import axios from "@/config/httpConfig";
// 更新用户上传头像
export function updatePersonContactInfo(data) {
    return axios.post("/ccchat/person/updatePersonContactInfo", data);
}
// 追随或取消追随,=关注，取消关注
export function setFollow(data) {
    return axios.post('/ccchat/person/setFollow', data)
}
// 查询伙伴云设置
export function queryPartnerSetup(data) {
    return axios.get('/partnerSetup/queryPartnerSetup', data)
}