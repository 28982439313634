<!-- 个人头部基本信息 -->
<template>
  <div class="detailsPersonal">
    <div class="topBox">
      <div class="topContent">
        <div class="pictureShow">
          <div class="personalInformation">
            {{ fieldValue }}
          </div>
          <div class="editPersonalInformation">
            <!-- 关注已关注 实现样式 -->
            <!-- 关注  label.chatter.follow -->
            <!-- 已关注 label.chatter.followed -->
            <div
              v-if="userID == id ? false : true"
              @click="favor(FocusOn)"
              class="guanZhu"
              @mouseenter="change()"
              @mouseleave="goback()"
            >
              <svg
                class="icon"
                aria-hidden="true"
                v-show="
                  this.FocusOn === this.$i18n.t('label.chatter.follow') ||
                  this.FocusOn === this.$i18n.t('label.chatter.followed')
                "
              >
                <use
                  :href="
                    this.FocusOn === this.$i18n.t('label.chatter.follow')
                      ? '#icon-' + tianjiaImg
                      : '#icon-' + duigouImg
                  "
                ></use>
              </svg>
              {{ FocusOn }}
            </div>
            <!-- 编辑个人信息   label.h5.editmydetails -->
            <div
              class="personNews"
              v-if="userID == id && showButton === 'true' ? true : false"
              @click="theEditor"
            >
              {{ $t("label.h5.editmydetails") }}
            </div>
          </div>
        </div>
        <!-- 小相机 -->
        <svg
          class="icon xiangji"
          @click="editPersonImg"
          v-if="userID == id ? true : false"
          aria-hidden="true"
        >
          <use href="#icon-xaingji"></use>
        </svg>
        <!-- 用户头像 -->
        <img :src="oldImgSrc" alt class="touxiang" />
      </div>
      <!-- 编辑个人信息 -->
      <el-button plain @click="linkTo">编辑个人信息</el-button>
    </div>
    <!-- 修改用户头像弹框 -->
    <UploadImg
      :savaStatus="savaStatus"
      :oldImgSrc="oldImgSrc"
      :uploadPictureTo="uploadPictureTo"
      @uploadPictureCancel="uploadPictureCancel"
      @uploadPictureConfirm="uploadPictureConfirm"
    />
  </div>
</template>

<script>
import { setFollow } from "./api.js";
import { queryPartnerSetup } from "./api.js";
import { updatePersonContactInfo } from "./api.js";
import VueCookies from "vue-cookies";
import UploadImg from "./UploadImg";

export default {
  components: {
    UploadImg,
  },
  name: "detailsPersonal",
  data() {
    return {
      userUrlImg: "",
      uploadPictureTo: false,
      userurl: "",
      userID: localStorage.getItem("userId"),
      liked: false,
      FocusOn: this.follow,
      signature: "",
      oldImgSrc: "",
      tianjiaImg: "tianjiablue",
      duigouImg: "duigoublue",
      savaStatus: false,
      showButton: true,
    };
  },
  props: {
    fieldValue: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    // 是否已关注
    follow: {
      type: String,
      default: "",
    },
  },
  watch: {
    id: {
      handler(newUsernameId) {
        this.oldImgSrc = `${VueCookies.get(
          "domainName"
        )}/chatterzone.action?m=showPhoto&userid=${newUsernameId}&binding=${this.$CCDK.CCToken.getToken()}&wj=${Math.random()}`;
      },
      immediate: true,
    },
    follow(nval) {
      this.FocusOn = nval;
    },
  },
  mounted() {
    this.userurl = this.$cookies.get("domainName");
    if (this.$store.state.userInfoObj.ispartner) {
      this.getShowTab();
    }
    this.oldImgSrc = `${VueCookies.get(
      "domainName"
    )}/chatterzone.action?m=showPhoto&userid=${
      this.id
    }&binding=${this.$CCDK.CCToken.getToken()}&wj=${Math.random()}`;
  },
  methods: {
    // 编辑个人信息
    linkTo() {
      // 跳转到新版个人设置页面
      // let personalSettting = this.$router.resolve("/profileSetting/");
      // window.open(personalSettting.href, "_blank");
      this.$router.push("/profileSetting/");
    },
    // 获取是否展示伙伴云伙伴站点以及伙伴财务设置
    async getShowTab() {
      let result = await queryPartnerSetup();
      this.showButton = result.data.isedituser;
    },
    editPersonImg() {
      this.uploadPictureTo = true;
    },
    // 取消修改用户头像
    uploadPictureCancel() {
      this.uploadPictureTo = false;
    },
    // 确认修改用户头像(更新用户上传头像)
    async uploadPictureConfirm(img) {
      this.savaStatus = true;
      setTimeout(() => {
        this.savaStatus = false;
      }, 1000 * 8);
      let params = {
        imgStr: img,
      };
      // 上传头像成功  vue_label_notice_uploadavatar_success
      // 上传头像失败   vue_label_notice_uploadavatar_fail
      try {
        let { data } = await updatePersonContactInfo(params);
        this.oldImgSrc = `${VueCookies.get(
          "domainName"
        )}/chatterzone.action?m=showPhoto&userid=${
          data.id
        }&binding=${this.$CCDK.CCToken.getToken()}&wj=${Math.random()}`;
        this.savaStatus = false;
        this.$message.success(
          this.$i18n.t("vue_label_notice_uploadavatar_success")
        );
        this.$Bus.$emit("change-image-top", true);
        this.$bus.$emit("newHeadPortrait"); //上传新头像后刷新同事圈头像
      } catch (err) {
        this.savaStatus = false;
        this.$message.error(this.$i18n.t("vue_label_notice_uploadavatar_fail"));
      } finally {
        this.savaStatus = false;
      }
      this.uploadPictureTo = false;
      this.$bus.$emit("uploadPicture");
    },
    //个性签名
    // open() {
    //   this.$prompt("关于我", {
    //     confirmButtonText: "保存",
    //     cancelButtonText: "取消",
    //     inputErrorMessage: "输入内容为空",
    //   })
    //     .then(({ value }) => {
    //       // this.$message({showClose: true,
    //       //   type: 'success',
    //       //   message: '你的邮箱是: ' + value
    //       // });
    //       this.signature = value;
    //     })
    //     .catch(() => {
    //       this.$message({showClose: true,
    //         type: "info",
    //         message: "取消输入",
    //       });
    //     });
    // },
    // 编辑个人信息
    theEditor() {
      window.open(
        `${
          this.userurl
        }/userselfmodify.action?vr=inno&setupId=PersonalInformation&binding=${this.$CCDK.CCToken.getToken()}`
      );
    },
    // 关注和取消关注
    async favor() {
      // 关注--> 已关注
      // 关注成功 vue_label_notice_follow_success
      if (this.FocusOn == this.$i18n.t("label.chatter.follow")) {
        let isFollowRes = await setFollow({
          id: this.id,
          followType: "record",
          followStatus: "1",
        });
        if (isFollowRes.result === true) {
          this.FocusOn = this.$i18n.t("label.chatter.followed");
          this.$message.success(
            this.$i18n.t("vue_label_notice_follow_success")
          );
        }
        this.$bus.$emit("followersTo");
      }
      // 取消关注-->关注
      // 取消关注  vue_label_CCChat_unfollow
      // 取消关注成功 vue_label_notice_unfollow_success
      else if (this.FocusOn == this.$i18n.t("vue_label_CCChat_unfollow")) {
        let cancleFollowRes = await setFollow({
          id: this.id,
          followType: "record",
          followStatus: "0",
        });
        if (cancleFollowRes.result === true) {
          this.FocusOn = this.$i18n.t("label.chatter.follow");
          this.$message.success(
            this.$i18n.t("vue_label_notice_unfollow_success")
          );
        }
        this.$bus.$emit("followersTo");
      }
    },
    change() {
      if (this.FocusOn == this.$i18n.t("label.chatter.followed")) {
        this.FocusOn = this.$i18n.t("vue_label_CCChat_unfollow");
      }
    },
    goback() {
      if (this.FocusOn == this.$i18n.t("vue_label_CCChat_unfollow")) {
        this.FocusOn = this.$i18n.t("label.chatter.followed");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detailsPersonal {
  margin-top: 24px;
  .topBox {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border: 1px solid #dddbda;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
  }
  .topContent {
    position: relative;
    .touxiang {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      border: solid 7px white;
      position: absolute;
      top: -30%;
      left: 32px;
      z-index: 100;
    }
    .xiangji {
      width: 21px;
      height: 21px;
      position: absolute;
      top: 50px;
      left: 100px;
      z-index: 200;
      cursor: pointer;
    }
    .pictureShow {
      width: 100%;
      min-height: 72px;
      display: flex;
      justify-content: space-between;
      .personalInformation {
        display: flex;
        align-items: center;
        margin-left: 135px;
        font-size: 14px;
        font-weight: 600;
        // .signature {
        //   display: flex;
        //   font-size: 14px;
        //   align-items: center;
        //   .bianjiTan {
        //     padding: 0;
        //     .bianji {
        //       cursor: pointer;
        //       width: 13px;
        //       height: 13px;
        //       margin-left: 8px;
        //       vertical-align: 0;
        //     }
        //   }
        // }
      }
      .editPersonalInformation {
        padding: 22px 30px 22px 0px;
        display: flex;
        align-items: center;
        font-size: 12px;
        .guanZhu {
          width: 73px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddbda;
          .icon {
            display: inline-block;
            margin-top: -1px;
            width: 12px;
            height: 12px;
          }
        }
        .personNews {
          padding: 4px 18px;
          text-align: center;
          background: #ffffff;
          border-radius: 3px;
          border: 1px solid #dddbda;
        }
        .guanZhu:hover,
        .personNews:hover {
          cursor: pointer;
          color: #006dcc;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-popover.popo_operation {
  width: 70px;
  min-width: 0;
  padding: 0;
  .name-box {
    padding: 15px;
    width: 246px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .demo-ruleForm {
      margin-top: 6px;
      ::v-deep .el-form-item {
        ::v-deep .el-form-item__label {
          width: 88px !important;
        }
        ::v-deep .el-form-item__content {
          margin-left: 88px !important;
          ::v-deep .el-form-item__error {
            padding-top: 4px !important;
          }
        }
      }
    }
    .butts {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      .el-button {
        padding: 6px 8px;
        border-radius: 4px;
      }
      ::v-deep .el-button--default {
        // background-color: #fff;
        color: #302c2c;
      }
      // primary
      ::v-deep .el-button--primary {
        // background-color: #409eff;
        // border-color: #409eff;
        margin-right: 6.5px;
      }
    }
  }
}
</style>
